import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'

export const getNewsletterBanner = (): CheckedComponentInformation => {
    return layout.component({
        type: 'pn-newsletter-banner-ngn',
        props: {},
    })
}

export const getDigitalEditionsBanner = (): CheckedComponentInformation => {
    return layout.component({
        type: 'pn-digital-edition-promo-ngn',
        props: {},
    })
}

export const getHomepagePromotion = (
    slot: number,
): CheckedComponentInformation => {
    return layout.component({
        type: 'pn-theme-card',
        props: {
            theme: 'default',
            dataDefinitionArgs: {
                type: 'active-promotion',
                promotionSlotId: `homepage-position-${slot}`,
                cardNumber: slot,
            },
        },
    })
}

export const getStreamerPromotion = () => {
    return layout.component({
        type: 'pn-theme-card',
        props: {
            theme: 'streamer-screamer',
            dataDefinitionArgs: {
                type: 'curation',
                name: `play-of-the-day`,
                offset: 0,
                pageSize: 1,
            },
        },
    })
}
